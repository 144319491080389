import GeneralHead from "components/front/head/GeneralHead";
import { Header } from "components/front/layout/Header";
import Link from "next/link";
import { Props as FooterProps } from "components/front/layout/Footer";
import { GeneralFoot } from "components/front/layout/GeneralFoot";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import dynamic from "next/dynamic";

const Footer = dynamic<FooterProps>(() => import("components/front/layout/Footer"));

const useStyles = makeStyles(() => ({
  linkButton: {
    margin: "20px auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& a": {
      width: "206px",
      height: "46px",
      backgroundImage: "url(/img/header_login_bg--pc.png)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        fontSize: "16px",
        textAlign: "center",
        transformOrigin: "center",
      },
    },
    "& a:hover": {
      animation: "loginFlash 4.5s step-start infinite",
      "& p": {
        textShadow: "-1px 0 0 lime, 1px 0 0 red",
      },
    },
  },
}));

export default function Custom404(): JSX.Element {
  const classes = useStyles();
  return (
    <>
      <GeneralHead screenName={"404エラー"} />
      <Header />
      <main>
        <div
          style={{
            margin: "40px 10px",
            minHeight: "276px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h1
              style={{
                fontSize: "24px",
                textAlign: "center",
                margin: "20px 0",
              }}
            >
              <span style={{ display: "inline-block", padding: "0 20px" }}>お探しのページは見つかりませんでした。</span>
            </h1>
            <div style={{ width: "100%", textAlign: "center" }}>
              <img src="/img/error.png" width={300} height={300} />
            </div>
            <div className={classes.linkButton}>
              <Link href={process.env.LANDING_POINT as string}>
                <a>
                  <p>TOPへ戻る</p>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <GeneralFoot />
    </>
  );
}
